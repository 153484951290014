const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

    (binding.modules['header'] = {
        c: () => require('partner/modules/header/header.tsx'),
        $type: 'containerModule',
        da: [{name:'accountInformation',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer', runOn: 0},{name:'cart',  path:'@msdyn365-commerce/global-state/dist/lib/data-actions/get-cart-state-data-action', runOn: 1},{name:'employee',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-employee', runOn: 0},{name:'storeSelectorStateManager',  path:'@msdyn365-commerce-modules/bopis-utilities/dist/lib/store-selector-state-manager/store-selector-state-manager-data-action', runOn: 1},{name:'wishlists',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-wishlist-by-customer-id', runOn: 0}],
        
        iNM: false,
        ns: '__local__',
        n: 'header',
        p: '__local__',
        
        pdp: '',
        
        
        md: 'src/modules/header'
    });
        

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/bopis-utilities/dist/lib/store-selector-state-manager/store-selector-state-manager-data-action';
            let dataAction = require('@msdyn365-commerce-modules/bopis-utilities/dist/lib/store-selector-state-manager/store-selector-state-manager-data-action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-employee';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-employee');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-wishlist-by-customer-id';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-wishlist-by-customer-id');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce/global-state/dist/lib/data-actions/get-cart-state-data-action';
            let dataAction = require('@msdyn365-commerce/global-state/dist/lib/data-actions/get-cart-state-data-action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|modules|header|header'] = {
                c: () => require('partner/modules/header/header.view.tsx'),
                cn: '__local__-__local__-header'
            };
viewDictionary['__local__|__local__|themes|nec|views|header'] = {
                c: () => require('partner/themes/nec/views/header.view.tsx'),
                cn: '__local__-__local__-header'
            };
viewDictionary['@msdyn365-commerce-modules|fabrikam-design-kit|modules|fabrikam|views|header'] = {
                c: () => require('@msdyn365-commerce-modules/fabrikam-design-kit/dist/lib/modules/fabrikam/views/header.view.js'),
                cn: '@msdyn365-commerce-modules-fabrikam-design-kit-header'
            };
viewDictionary['@msdyn365-commerce-modules|header|modules|header|header'] = {
                c: () => require('@msdyn365-commerce-modules/header/dist/lib/modules/header/header.view.js'),
                cn: '@msdyn365-commerce-modules-header-header'
            };
viewDictionary['@msdyn365-commerce-theme|adventureworks-theme-kit|modules|adventureworks|views|header'] = {
                c: () => require('@msdyn365-commerce-theme/adventureworks-theme-kit/dist/lib/modules/adventureworks/views/header.view.js'),
                cn: '@msdyn365-commerce-theme-adventureworks-theme-kit-header'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };